import { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { TAwardsAndPressProps } from "../types";

export const AwardsAndPressMob: React.FC<TAwardsAndPressProps> = ({
  ...props
}) => {
  const { isMobile, arr1, arr2, title1, title2 } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animateKey, setAnimateKey] = useState(0);

  useEffect(() => {
    if (isMobile) {
      const intervalId = window.setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % arr1.length);
        setAnimateKey((prevKey) => prevKey + 1);
      }, 3000);

      return () => window.clearInterval(intervalId);
    }
  }, [isMobile, arr1.length]);
  const placeholder =
    "data:image/bmp;base64,Qk32BAAAAAAAADYAAAAoAAAACAAAAAgAAAABABgAAAAAAMAAAAATCwAAEwsAAAAAAAAAAAAAKCgoMTExPj4+QUFBOjo6LCwsJSUlKioqHBwcKCgoODg4PT09NTU1JSUlHh4eJiYmCgoKHh4eMjIyODg4Li4uGxsbFBQUIiIiCwsLHR0dMDAwNDQ0KCgoEhISDw8PIiIiICAgKCgoMzMzMzMzJSUlDw8PFRUVKCgoMjIyNjY2OTk5NTU1JSUlFBQUHh4eMDAwPj4+Pz8/Pz8/Nzc3JycnGhoaJSUlNjY2QkJCQ0NDQUFBODg4KCgoHBwcKCgoOTk5";

  return (
    <section className="awards wrapper animate">
      <div className="awards__title-wrapper">
        <h3 className="awards__title1 animation__text-rise">{title1}</h3>
        <h3 className="awards__title2 animation__text-rise">{" " + title2}</h3>
      </div>
      <div className="awards__container">
        <div className="awards__wrapper awards__wrapper_1">
          {[arr2[currentIndex], arr2[(currentIndex + 1) % arr2.length]].map(
            (item, index) => (
              <div
                key={item.title + index + animateKey}
                className="awards__item card visible"
              >
                <Image
                  className="awards__img"
                  src={`https://api.blwall.com${item.image.data.attributes.url}`}
                  alt={item.moreText ? "press img" : "award img"}
                  width={300}
                  height={300}
                  title={item.title}
                  loading="lazy"
                  blurDataURL={placeholder}
                  placeholder="blur"
                />
                <div className="awards__text">
                  <h3 className="awards__itemTitle">{item.title}</h3>
                  {item.subtitle && (
                    <Link
                      href={item.link || ""}
                      className="awards__itemSubtitle"
                      target="_blank"
                    >
                      {item.subtitle}
                    </Link>
                  )}
                </div>
              </div>
            )
          )}
        </div>
        <div className="awards__wrapper awards__wrapper_2">
          {[arr1[currentIndex], arr1[(currentIndex + 1) % arr1.length]].map(
            (item, index) => (
              <div
                key={item.title + index + animateKey}
                className="awards__item card visible"
              >
                <Image
                  className="awards__img"
                  src={`https://api.blwall.com${item.image.data.attributes.url}`}
                  alt={item.moreText ? "press img" : "award img"}
                  width={300}
                  height={300}
                  title={item.title}
                  loading="lazy"
                />
                <div className="awards__text">
                  <h3 className="awards__itemTitle">{item.title}</h3>
                  {item.subtitle && (
                    <Link
                      href={item.link || ""}
                      className="awards__itemSubtitle"
                    >
                      {item.subtitle}
                    </Link>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </section>
  );
};
